<template>
    <v-menu
            offset-y
            left
            bottom
            transition="scale-transition"
            origin="center center"
            rounded="lg"
            class="overflow-hidden"
            min-width="280"
            max-width="280"
            :close-on-content-click="false"
            v-model="menu"
    >
        <template v-slot:activator="{ on }">
            <v-btn icon class="mr-4" small v-on="on">
                <v-icon size="18">mdi-account</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-row class="mx-0 cell2">
                <v-col cols="12" class="text-center pt-6" v-if="contact">
                    <div
                            style="border-radius: 50%; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; color: white;"
                            class="secondary title-1 font-weight-bold mx-auto text-truncate"
                    >
                        {{ contact ? contact.display_name.substr(0, 1) : '' }}
                    </div>
                    <div class="mt-3 text-truncate sub-title-2">{{ contact ? contact.email : '' }}</div>
                </v-col>
                <v-col cols="12" class="text-center px-6" v-if="contact">
                    <SelectAccount />
                </v-col>
                <v-col cols="12" class="px-6" v-if="contact">
                    <SelectSite />
                </v-col>
                <v-col cols="12" v-if="contact" class="pa-0">
                    <v-divider class="mt-1" />
                </v-col>
                <v-col cols="12" class="pa-0 cell">
                    <v-row class="mx-0 pa-2" justify="end" dense align="center">
                        <v-col
                                :cols="6"
                                v-for="(item,i) in profile_menu"
                                :key="i"
                                class="text-center d-flex"
                                style="min-height: 60px; align-items: center"
                        >
                            <v-btn v-if="item.name !== 'theme_mode' && item.name !== 'visibility'" class="rounded-lg" style="width: 120px; height: 60px" text @click="item.click">
                                <v-col class="pa-0">
                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                    <div class="mt-2 caption-1">{{ $t('navbar.profile_menu.' + item.name) }}</div>
                                </v-col>
                            </v-btn>
                            <Select
                                v-else-if="item.name === 'theme_mode'"
                                v-model="theme"
                                :items="Object.keys($tools.themes)"
                                :label="$t('navbar.profile_menu.theme')"
                                class="pt-6"
                                :attach="false"
                                :itemText="''"
                            />
                            <div v-else style="width: 100%">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters align="center">
                                    <v-col>
                                        <v-btn icon small @click="item.click[1]">
                                            <v-icon small>mdi-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col style="min-width: 47px">
                                        <TextField v-model="zoom" />
                                    </v-col>
                                    <v-col>
                                        <v-btn icon small @click="item.click[0]">
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "ProfileMenu",
        props: ['contact', 'marketplace', 'pevents'],
        components: {
            SelectSite: () => import('@/components/navbar/SelectSite.vue'),
            SelectAccount: () => import('@/components/navbar/SelectAccount.vue'),
            TextField: () => import('@/components/fields/TextField.vue'),
            Select: () => import('@/components/fields/Select.vue')
        },
        data(){
            return {
                styles: '',
                menu: false,
                profile_menu_default: [
                    {
                        name: "signin",
                        icon: 'mdi-login-variant',
                        route_name: 'signin',
                        click: () => { this.menu = false ; this.$router.push('/auth/login') }
                    },
                    {
                        name: "signup",
                        icon: 'mdi-account-plus',
                        route_name: 'signup',
                        click: () => { this.menu = false ; this.$router.push('/auth/register') }
                    },
                    {
                        name: "dashboard",
                        icon: 'mdi-view-dashboard',
                        route_name: 'home',
                        click: () =>  { this.menu = false ; this.$router.push('/dashboard') }
                    },
                    {
                        name: "marketplace",
                        icon: 'mdi-shopping',
                        route_name: 'Catalog',
                        click: () =>  { this.menu = false ; this.$router.push('/marketplace') }
                    },
                    {
                        name: "dark_mode",
                        icon: this.dark_mode ? 'mdi-checkbox-marked':'mdi-checkbox-blank-outline',
                        route_name: '',
                        click: () =>  { this.menu = false ; this.setDark() }
                    },
                    {
                        name: "theme_mode",
                        route_name: '',
                        click: () =>  { this.menu = false ; this.$vuetify.theme.themes = this.$tools.DjangoGirls }
                    },
                    {
                        name: "visibility",
                        route_name: '',
                        icon: 'mdi-format-text',
                        click: [
                            () =>  { this.zoom++ },
                            () =>  { this.zoom-- }
                        ]
                    },
                    {
                        name: "logout",
                        icon: 'mdi-logout-variant',
                        route_name: '',
                        click: () =>  { this.menu = false; this.$store.dispatch('auth/logOut'); this.pevents.notify('update-app') }
                    }
                ]
            }
        },
        computed: {
            zoom: {
                get(){
                    let element = document.getElementsByTagName('html')[0]
                    element.style = this.styles + `; font-size: ${this.$store.getters['profile/zoom']}% !important`
                    return this.$store.getters['profile/zoom']
                },
                set(val){
                    let element = document.getElementsByTagName('html')[0]
                    element.style = this.styles + `; font-size: ${val}% !important`
                    this.$store.commit('profile/CHANGE_ZOOM_VAL', val)
                }
            },
            dark_mode: {
                get(){
                    this.$vuetify.theme.dark = this.$store.getters['profile/dark_mode']
                    return this.$store.getters['profile/dark_mode']
                },
                set(val){
                    this.$vuetify.theme.dark = val
                    this.$store.commit('profile/CHANGE_DARKMODE_VAL', val)
                }
            },
            theme: {
                get(){
                    this.$vuetify.theme.themes = this.$tools.themes[this.$store.getters['profile/theme_mode']]
                    return this.$store.getters['profile/theme_mode']
                },
                set(val){
                    this.$vuetify.theme.themes = this.$tools.themes[val]
                    this.$store.commit('profile/CHANGE_THEMEMODE_VAL', val)
                }
            },
            profile_menu(){
                let res = []

                this.profile_menu_default.forEach(e => {
                    if(this.contact){
                        if(e.name !== 'signin' && e.name !== 'signup') {
                            if(!this.$route.path.match('/dashboard') && e.name === 'dashboard'){
                                res.push(e)
                            }
                            else if(!this.$route.path.match('/marketplace') && e.name === 'marketplace' && this.marketplace) {
                                res.push(e)
                            }
                            else if(e.name === 'logout' || e.name === 'dark_mode' || e.name === 'theme_mode' || e.name === 'visibility'){
                                res.push(e)
                            }
                        }
                    }
                    else {
                        if(e.name !== 'dashboard' && e.name !== 'logout') {
                            if((this.$route.name === 'catalog' || this.$route.name === 'product') && e.name === 'marketplace' && this.marketplace){

                            }
                            else if(this.$route.name === 'login' && e.name === 'signin'){

                            }
                            else if(this.$route.name === 'register' && e.name === 'signup'){

                            }
                            else {
                                res.push(e)
                            }
                        }
                    }
                });

                return res
            }
        },
        watch: {
            dark_mode(val){
                let item = this.profile_menu.find(e => e.name === 'dark_mode')
                item.icon = val ? 'mdi-checkbox-marked':'mdi-checkbox-blank-outline'
            }
        },
        methods: {
            setDark(){
                this.dark_mode = !this.dark_mode
                let item = this.profile_menu.find(e => e.name === 'dark_mode')
                item.icon = this.dark_mode ? 'mdi-checkbox-marked':'mdi-checkbox-blank-outline'
            }
        },
        mounted(){
            this.styles = document.getElementsByTagName('html')[0].getAttribute('style')
        }
    }
</script>
